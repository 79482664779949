<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <div id="form" style="width: 100%">
                  <!-- <pre>{{formData}}</pre> -->
                  <fieldset class="p-2">
                    <legend class="px-2 w-50 shadow-sm">{{ $t('li_step.circular') }} {{$t('globalTrans.details') }}</legend>
                    <b-col lg="12" sm="12" class="list-it">
                      <b-table-simple bordered hover striped class="list-it">
                        <b-tr>
                          <b-th>
                            {{ $t('globalTrans.fiscal_year')}}
                          </b-th>
                          <b-td>
                            {{ getColumnName($store.state.commonObj.fiscalYearList, formData.fiscal_year_id)}}
                          </b-td>
                          <b-th>
                            {{ $t('globalTrans.office')}}
                          </b-th>
                          <b-td>
                            {{ getColumnName($store.state.commonObj.officeList, formData.office_id)}}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>
                            {{ $t('globalTrans.title')}}
                          </b-th>
                          <b-td>
                            {{ currentLocale === 'bn' ? formData.title_bn : formData.title_en }}
                          </b-td>
                          <b-th>
                            {{ $t('li_step.circular_memo_no')}}
                          </b-th>
                          <b-td>
                            {{ formData.circular_memo_no }}
                          </b-td>
                        </b-tr>
                        <b-tr>
                          <b-th>
                            {{ $t('li_step.circular_start_date')}}
                          </b-th>
                          <b-td>
                             {{ formData.start_date | dateFormat }}
                          </b-td>
                          <b-th>
                            {{ $t('li_step.circular_end_date')}}
                          </b-th>
                          <b-td>
                            {{ formData.end_date | dateFormat }}
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                  </fieldset>
                  <fieldset class="p-2">
                    <legend class="px-2 w-50 shadow-sm">{{ $t('li_step.deadline_history') }} {{$t('globalTrans.details') }}</legend>
                    <b-col lg="12" sm="12">
                      <b-table-simple bordered hover striped class="list-it">
                        <b-tr>
                          <b-th>
                            {{ $t('globalTrans.sl_no')}}
                          </b-th>
                          <b-th>
                            {{ $t('li_step.circular_end_date')}}
                          </b-th>
                          <b-th>
                            {{ $t('globalTrans.remarks')}}
                          </b-th>
                        </b-tr>
                        <slot v-if="formData.history.length > 0">
                          <b-tr v-for="(history, index) in formData.history" :key="index">
                            <th scope="row">{{$n(index+1)}}</th>
                            <td>{{ history.end_date | dateFormat }}</td>
                            <td>{{ history.description }}</td>
                          </b-tr>
                        </slot>
                        <slot v-else>
                          <b-tr>
                            <th colspan="3" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                          </b-tr>
                        </slot>
                      </b-table-simple>
                    </b-col>
                  </fieldset>
                  <b-col lg="12" sm="12">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <b-row class="mt-2">
                          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Circular End Date" vid="end_date" rules="required" v-slot="{ errors }">
                              <b-form-group
                                label-for="end_date">
                                <template v-slot:label>
                                  {{ $t('li_step.circular_end_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                  id="datepicker"
                                  placeholder="yyyy-mm-dd"
                                  v-model="extendData.end_date"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback d-block" v-if="errors.length">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="remarks" vid="remarks">
                              <b-form-group
                                label-for="remarks"
                              >
                                <template v-slot:label>
                                    {{ $t('globalTrans.remarks') }}
                                </template>
                                <b-form-textarea
                                  id="remarks"
                                  v-model="extendData.description"
                                  :placeholder="$t('globalTrans.remarks')"
                                ></b-form-textarea>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row class="text-right">
                          <b-col>
                              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </div>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.list-it th, td {
  padding: 5px !important;
}
</style>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { recruitmentHistoryStore } from '../../api/routes'
import flatpickr from 'flatpickr'
export default {
  name: 'Form',
  components: {
  },
  props: ['id'],
  data () {
    return {
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        fiscal_year_id: 0,
        org_id: 3,
        service_id: 19,
        office_type_id: 132,
        office_id: 0,
        circular_date: '',
        circular_memo_no: '',
        start_date: '',
        end_date: '',
        description_bn: '',
        description_en: '',
        title_en: '',
        title_bn: '',
        attachment: []
      },
      extendData: {
        seed_deal_recruitment_circular_id: 0,
        end_date: '',
        description: ''
      },
      upload_attachment: [],
      loading: false,
      config: { static: true }
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.formData = tmp
      this.extendData.seed_deal_recruitment_circular_id = this.formData.id
    }
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin === 0) {
      this.formData.office_id = this.$store.state.Auth.authUser.office_id
    }
  },
  watch: {
  },
  computed: {
    detailsTitle () {
      return this.$t('li_step.circular') + ' ' + this.$t('globalTrans.details')
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === 3 && item.office_type_id === 132).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    }
  },
  methods: {
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
          return ''
      }
    },
    onFileChange (e) {
      this.upload_attachment = e.target.files[0]
    },
    // onFileChange (event) {
    //   const input = event.target
    //   if (input.files && input.files[0]) {
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.formData.attachment = e.target.result
    //     }
    //     reader.readAsDataURL(input.files[0])
    //   } else {
    //     this.formData.attachment = ''
    //   }
    // },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.loading = true
      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, recruitmentHistoryStore, this.extendData)
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-extend')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
