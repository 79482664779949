<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                      <!-- <div>
                        <p style="font-size:17px;color:#77A0CB"><i class="ri-home-7-line"></i> {{ $t('license_management.division_district_status') }}</p>
                      </div> -->
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('globalTrans.office') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Title (Bn)" vid="title_bn" rules="required|max:255">
                            <b-form-group
                              label-for="title_bn"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('li_step.title_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="formData.title_bn"
                              id="title_bn"
                              placeholder="Title Bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Title (En)" vid="title_en" rules="required|max:255">
                            <b-form-group
                              label-for="title_en"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('li_step.title_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              plain
                              v-model="formData.title_en"
                              id="title_en"
                              placeholder="Title En"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="6" sm="6" md="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="circular_memo_no">
                              <template v-slot:label>
                                {{ $t('li_step.circular_memo_no') }}<span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="circular_memo_no"
                                placeholder="Circular Memo No"
                                v-model="formData.circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              <p class="text-danger pt-2 pb-0"><b>{{ $t('elearning_iabm.circular_memo_note_badc')}}</b></p>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Circular Date" vid="circular_date" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="circular_date">
                              <template v-slot:label>
                                {{ $t('li_step.circular_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="datepicker"
                                placeholder="yyyy-mm-dd"
                                v-model="formData.circular_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Circular Start Date" vid="start_date" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="start_date">
                              <template v-slot:label>
                                {{ $t('li_step.circular_start_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="datepicker"
                                placeholder="yyyy-mm-dd"
                                v-model="formData.start_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback d-block" v-if="errors.length">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Circular End Date" vid="end_date" rules="required" v-slot="{ errors }">
                            <b-form-group
                              label-for="end_date">
                              <template v-slot:label>
                                {{ $t('li_step.circular_end_date') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="datepicker"
                                placeholder="yyyy-mm-dd"
                                v-model="formData.end_date"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-input>
                              <div class="invalid-feedback d-block" v-if="errors.length">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                          <ValidationProvider name="Description (Bn)" vid="description_bn"  rules="required">
                            <b-form-group
                              label-for="description_bn"
                              slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.description_bn')}} <span class="text-danger">*</span>
                            </template>
                            <vue-editor v-model="formData.description_bn"></vue-editor>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                          <ValidationProvider name="Description (En)" vid="description_en"  rules="required">
                            <b-form-group
                              label-for="description_en"
                              slot-scope="{ errors }"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.description_en')}} <span class="text-danger">*</span>
                            </template>
                            <vue-editor v-model="formData.description_en"></vue-editor>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Attachment" vid="attachment" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="attachment"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('globalTrans.attachment')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-file
                                id="attachment"
                                v-on:change="onFileChange"
                                accept=".jpeg,.jpg,.pdf"
                                v-model="formData.attachment"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6">
                          <ValidationProvider name="Manual" vid="manual">
                            <b-form-group
                                class="row"
                                label-for="manual"
                                slot-scope="{ errors }"
                            >
                                <b-form-checkbox
                                    id="manual"
                                    v-model="formData.manual"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    {{$t('li_step.is_this_circular_is_for_reniew')}}
                                </b-form-checkbox>
                                <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="text-right">
                        <b-col>
                            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { recruitmentStore, recruitmentUpdate } from '../../api/routes'
import { VueEditor } from 'vue2-editor'
import flatpickr from 'flatpickr'
export default {
  name: 'Form',
  components: {
    VueEditor
  },
  props: ['id'],
  data () {
    return {
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        fiscal_year_id: 0,
        org_id: 3,
        service_id: 19,
        office_type_id: 132,
        office_id: 0,
        circular_date: '',
        circular_memo_no: '',
        start_date: '',
        end_date: '',
        description_bn: '',
        description_en: '',
        title_en: '',
        title_bn: '',
        attachment: []
      },
      upload_attachment: [],
      loading: false,
      config: { static: true }
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.formData = tmp
    }
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin === 0) {
      this.formData.office_id = this.$store.state.Auth.authUser.office_id
    }
  },
  watch: {
  },
  computed: {
    detailsTitle () {
      return this.$t('li_step.circular') + ' ' + this.$t('globalTrans.details')
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    officeList: function () {
      return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.org_id === 3 && item.office_type_id === 132).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    }
  },
  methods: {
    onFileChange (e) {
      this.upload_attachment = e.target.files[0]
    },
    // onFileChange (event) {
    //   const input = event.target
    //   if (input.files && input.files[0]) {
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.formData.attachment = e.target.result
    //     }
    //     reader.readAsDataURL(input.files[0])
    //   } else {
    //     this.formData.attachment = ''
    //   }
    // },
    async saveUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formValue = new FormData()
      Object.keys(this.formData).map(key => {
        if (key === 'attachment') {
          formValue.append(key, this.upload_attachment)
        } else {
          formValue.append(key, this.formData[key])
        }
      })
      if (this.id) {
        formValue.append('_method', 'POST')
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, `${recruitmentUpdate}/${this.id}`, formValue, config)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, recruitmentStore, formValue, config)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#ee5253'
        })
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
