<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('li_step.circular') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(searchData)" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" >
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="circular_memo_no"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('li_step.circular_memo_no') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.circular_memo_no"
                      id="circular_memo_no"
                      placeholder="Circular Memo No"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="Start Date" vid="start_date" >
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="start_date"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.from_date') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.start_date"
                      id="start_date"
                      placeholder="yyyy-mm-dd"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6" md="6">
                <ValidationProvider name="End Date" vid="end_date" >
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="end_date"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.to_date') }}
                    </template>
                    <b-form-input
                      plain
                      v-model="search.end_date"
                      id="end_date"
                      placeholder="yyyy-mm-dd"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                    </b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <ValidationProvider name="Type">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="type"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('globalTrans.type') }}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.type"
                      :options="dataType"
                      id="type"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col class="col-sm-2">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('li_step.circular') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-form @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                  <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(circular_memo_no)="data">
                  {{ EngBangNum(data.item.circular_memo_no )}}
                  </template>
                  <template v-slot:cell(circular_date)="data">
                  {{ data.item.circular_date | dateFormat }}
                  </template>
                  <template v-slot:cell(start_date)="data">
                  {{ data.item.start_date | dateFormat }}
                  </template>
                  <template v-slot:cell(end_date)="data">
                  {{ data.item.end_date | dateFormat }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                    <span class="badge badge-success" v-else-if="data.item.status == 2">{{$t('globalTrans.published')}}</span>
                  </template>
                  <template v-slot:cell(manual)="data">
                    <span class="badge pay-status badge-primary w-100 text-white font-weight-bold" v-if="data.item.manual === 0">
                      {{ $t('globalTrans.new') }}
                    </span>
                    <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-else-if="data.item.manual === 1">
                      {{ $t('globalTrans.renew') }}
                    </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <a href="javascript:" variant="iq-bg-success mr-1 border" :title="$t('globalTrans.details')" v-b-modal.modal-form-details class="btn_table_action table_action_view" size="sm" @click="details(data.item)"><i class="ri-eye-line m-1"></i></a>
                    <a href="javascript:" variant="iq-bg-success mr-1 border" v-b-modal.modal-form size="sm" @click="edit(data.item)" class="btn_table_action table_action_edit" :title="$t('globalTrans.edit')"><i class="ri-ball-pen-line m-1"></i></a>
                    <a href="javascript:" variant="iq-bg-success mr-1 border" v-b-modal.modal-extend size="sm" @click="edit(data.item)" class="btn_table_action table_action_edit" :title="$t('li_step.extended_deadline')"><i class="ri-calendar-2-line"></i></a>
                    <a href="javascript:" variant="iq-bg-success mr-1 border" v-if="data.item.status === 1" size="sm" @click="publishStatus(data.item)" class="btn_table_action table_action_status" :title="$t('globalTrans.publish')"><i class="fa fa-paper-plane"></i></a>
                  </template>
                </b-table>
                </b-col>
                <b-col md="12" class="table-responsive">
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-extend" size="lg" :title="this.$t('li_step.extended_deadline')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Extend :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-form-details" size="lg" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId" :item="item" />
    </b-modal>
  </b-container>
</template>
<script>
// import Transfer from './Transfer'
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { recruitmentList, recruitmentCircularPublish } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-base-list'
import Form from './Form.vue'
import Extend from './Extend.vue'
import Details from './Details.vue'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ValidationObserver,
    ValidationProvider,
    Form,
    Extend,
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        office_id: 0,
        type: 0,
        circular_memo_no: '',
        start_date: '',
        end_date: ''
      },
      stepChange: {
        application_id: 0,
        step_id: 0,
        service_id: 0,
        org_id: 0,
        status: 1
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1 },
        { labels: 'globalTrans.fiscal_year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
        { labels: 'li_step.title', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2 },
        { labels: 'li_step.circular_memo_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3 },
        { labels: 'li_step.circular_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4 },
        { labels: 'li_step.circular_start_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5 },
        { labels: 'li_step.circular_end_date', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6 },
        { labels: 'externalLrcpn.type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6 },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8 },
        { labels: 'globalTrans.action', class: 'text-center', sortable: true, stickyColumn: false, show: '1', order: 9 }
      ],
      editItem: '',
      item: '',
      editItemId: ''
    }
  },
  computed: {
    dataType () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ' }
      ]
      return userList
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    dynamicFormList: function () {
      return this.$store.state.licenseRegistration.commonObj.dynamicFormList
    },
    serviceList: function () {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1)
    },
    stepList: function () {
      return this.$store.state.licenseRegistration.commonObj.stepNamesList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('li_step.circular') + ' ' + this.$t('globalTrans.entry') : this.$t('li_step.circular') + ' ' + this.$t('globalTrans.modify')
    },
    detailsTitle () {
      return this.$t('li_step.circular') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'title_bn' },
          { key: 'circular_memo_no' },
          { key: 'circular_date' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'manual' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'title_en' },
          { key: 'circular_memo_no' },
          { key: 'circular_date' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'manual' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    flatpickr('#start_date', {})
    flatpickr('#end_date', {})
  },
  created () {
    this.loadData()
    if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin === 0) {
      this.search.office_id = this.$store.state.Auth.authUser.office_id
    }
  },
  methods: {
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    publishStatus (item) {
      this.changeStatus(licenseRegistrationServiceBaseUrl, recruitmentCircularPublish, item)
    },
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    detailsData (item) {
      this.detailsItemId = item.id
    },
    edit (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, recruitmentList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.relationData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    relationData (data) {
      const listData = data.map(item => {
        this.stepChange.org_id = item.org_id
        this.stepChange.service_id = item.service_id
        // this.stepChange.application_id = item.application_id
        const serviceList = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(serviceItem => serviceItem.value === parseInt(item.service_id))
        const orgList = this.organizationList.find(orgItem => orgItem.value === parseInt(item.org_id))
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === parseInt(item.fiscal_year_id))
        const serviceObj = {
          service_name_en: serviceList !== undefined ? serviceList.text_en : '',
          service_name_bn: serviceList !== undefined ? serviceList.text_bn : ''
        }
        const orgObj = {
          org_name_en: orgList !== undefined ? orgList.text_en : '',
          org_name_bn: orgList !== undefined ? orgList.text_bn : ''
        }
        const fiscalYearObj = {
          fiscal_year_en: fiscalYearList !== undefined ? fiscalYearList.text_en : '',
          fiscal_year_bn: fiscalYearList !== undefined ? fiscalYearList.text_bn : ''
        }
        return Object.assign({}, item, serviceObj, orgObj, fiscalYearObj)
      })
      return listData
    },
    getServiceName (serviceId) {
      const ServiceName = this.serviceList.find(item => item.value === serviceId)
      return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
    }
  }
}
</script>
